<template>
  <div id="container" class="gnb_pdt">
    <div class="title_wrap"><h1>물류템플릿관리 상세</h1></div>
    <div class="contents_wrap">
      <div class="small_title_wrap"><h2>기본 정보</h2></div>
      <ul class="filter_list">
        <li>
          <dd><label>회사</label>
            <ul>
              <li>
                {{ logiInfo.cmpyNm }}
              </li>
            </ul>
          </dd>
          <dd><label>회사코드</label>
            <ul>
              <li>
                {{ logiInfo.cmpyCd }}
              </li>
            </ul>
          </dd>
        </li>
        <li>
          <dd><label>적용그룹코드(템플릿)</label>
            <ul>
              <li>
                {{logiInfo.logiDepth1Nm}}
              </li>
            </ul>
          </dd>
          <dd><label>그룹코드</label>
            <ul>
              <li>
                {{ logiInfo.logiDepth1 }}
              </li>
            </ul>
          </dd>
        </li>
        <li>
          <dd><label>사용여부</label>
            <ul>
              <li>{{logiInfo.activeYn === 'Y' ? '활성화' : logiInfo.activeYn === 'N' ? '비활성화' : ''}}</li>
            </ul>
          </dd>
        </li>
        <li>
          <dd><label>최초등록</label>
            <ul>
              <li>{{logiInfo.lastMdfyDt}} &nbsp | &nbsp {{logiInfo.lastMdfyNm }}</li>
            </ul>
          </dd>
          <dd><label>최종수정</label>
            <ul>
              <li>{{logiInfo.lastMdfyDt}} &nbsp | &nbsp {{logiInfo.lastMdfyNm }}</li>
            </ul>
          </dd>
        </li>
      </ul>
    </div>

    <div class="contents_wrap">
      <div class="small_title_wrap">
        <h2>적용항목</h2>
      </div>
      <common-grid-custom
          :max-height="386"
          :class="'mgt20'"
          :scrollable="true"
          :data-source="logiDepth2List"
          :columns="logiDepth2GridColumns"
          :noRecords="{template:'<div class=\'gridNoData\' style=\'text-align: center;\'>표시할 데이터가 없습니다.</div>',}"
      >
        <template #useYn="{rowItem}">
          <kendo-switch
              :checked="rowItem.data.useYn==='Y'"
              :disabled="true">
          </kendo-switch>
        </template>
      </common-grid-custom>
    </div>

    <!--- 버튼영역 --->
    <div class="button_area w25">
      <ul>
        <li class="w30">
          <button class="large_btn"
                  @click="$router.push({ name: 'LogisticsTemplateManageList' })">목록</button>
        </li>
        <li class="w30">
          <button class="large_btn dgray"
                  @click="onClickDeleteLogi">삭제</button>
        </li>
        <li class="w30">
          <button class="large_btn orange"
                  @click="$router.push({name: 'LogisticsTemplateManageEdit' })">수정</button>
        </li>
      </ul>
    </div>

  </div>
</template>

<script>

import ApiUtil from '/src/api/api.util'
import ApiConfig from '/src/api/api.config'
import EtnersCommonUtil from '@ebmp-fe-common-module/module/src/common/etners.common.util'

export default {
  name      : 'LogisticsTemplateManageDetail',
  watch : {
    logiDepth2List : function(newValue, oldValue){
      EtnersCommonUtil.setRowNumber(newValue)
    }
  },
  created () {
    if (!!this.$route.params.cmpyCd && !!this.$route.params.logiDepth1) {
      this.isNew = false
      this.getLogiManageDetail()
    }
  },
  methods   : {
    getLogiManageDetail : function() {
      const vm = this
      ApiUtil.query(`${ApiConfig.efsDomain}/efs/module/logi/${vm.$route.params.cmpyCd}/${vm.$route.params.logiDepth1}`)
      .then(function (response) {
            if (response.data.resultStatus.messageCode === '2000') {
              vm.logiInfo = response.data.resultData
              vm.logiDepth2List = response.data.resultData.logiDepth2List
            } else {
              kendo.alert(response.data.resultStatus.messageText)
            }
          },
      )
    },
    onClickDeleteLogi : function (){
      const vm = this
      kendo.confirm('템플릿을 삭제하시겠습니까?').done(function (){
        vm.deleteLogi()
      })
    },
    deleteLogi : function (){
      const vm = this
      ApiUtil.delete(`${ApiConfig.efsDomain}/efs/module/logi/${vm.$route.params.cmpyCd}/${vm.$route.params.logiDepth1}`)
      .then(function (response) {
            if (response.data.resultStatus.messageCode === '2000') {
              kendo.alert('삭제되었습니다.').bind('close', function() {
                vm.$router.push({ name: 'LogisticsTemplateManageList' })
              })
            } else {
              kendo.alert(response.data.resultStatus.messageText)
            }
          },
      )
    },
    onClickLogiInfoSave () {
      const vm = this
      if (vm.validator()) {
        kendo.confirm('저장 하시겠습니까?').done(
          function () {
            vm.logiManageSave()
          })
      }
    },
    logiManageSave() {
      const vm = this
      vm.logiDepth2List.forEach(item=>{
        item.useYn = item.VModelUseYn ? 'Y' : 'N'
      })
      vm.logiInfo.logiDepth2List = vm.logiDepth2List

      if(vm.isNew){
        ApiUtil.post(`${ApiConfig.efsDomain}/efs/module/logi`, vm.logiInfo).then(response => {
          if (!response.data?.resultStatus) {
            kendo.alert('데이터가 정상적으로 처리되지 않았습니다.')
            return false
          }
          if (response.data.resultStatus.messageCode !== '2000') {
            kendo.alert(response.data.resultStatus.messageText)
            return false
          }
          vm.$router.push({
            name: 'LogisticsTemplateManageDetail',
            params: {
              cmpyCd : response.data.resultData.cmpyCd,
              logiDepth1 : response.data.resultData.logiDepth1
            }
          })
        })
      }else {
        ApiUtil.put(`${ApiConfig.efsDomain}/efs/module/logi/${vm.$route.params.cmpyCd}/${vm.$route.params.logiDepth1}`, vm.logiInfo).then(response => {
          if (!response.data?.resultStatus) {
            kendo.alert('데이터가 정상적으로 처리되지 않았습니다.')
            return false
          }
          if (response.data.resultStatus.messageCode !== '2000') {
            kendo.alert(response.data.resultStatus.messageText)
            return false
          }
          vm.$router.push({
            name: 'LogisticsTemplateManageDetail',
            params: {
              cmpyCd : vm.$route.params.cmpyCd,
              logiDepth1 : vm.$route.params.logiDepth1
            }
          })
        })
      }
    },
    validator(){
      const vm = this
      if(!vm.logiInfo.cmpyCd){
        kendo.alert("회사를 선택해주세요.").bind('close', function() {
          vm.$refs.cmpyCdRef.widget().toggle()
        })
        return
      }
      if(!vm.logiInfo.logiDepth1){
        kendo.alert("적용그룹코드를 선택해주세요.").bind('close', function() {
          vm.$refs.logiDepth1Ref.kendoWidget().toggle()
        })
        return
      }

      return true
    },
  },
  data      : function () {
    const headerAttributes = { style: 'font-weight: bold; text-align: center;' }
    const centerAttributes = { style: 'text-align: center;' }
    return {
      ApiConfig : { efsDomain : ApiConfig.efsDomain },
      isNew : true,
      logiInfo  : {
        activeYn : 'Y'
      },
      rowItem : {
        data : {},
        index : null,
      },
      logiDepth2List : [],
      logiDepth2GridColumns : [
        {
          field: 'rowNumber',
          title: 'No',
          width: '3%',
          headerAttributes: headerAttributes,
          attributes: centerAttributes
        },
        {
          field: 'logiDepth2',
          title: '코드번호',
          width: '10%',
          headerAttributes: headerAttributes,
          attributes: centerAttributes
        },
         {
          field: 'logiDepth2Nm',
          title: '항목코드명',
          width: '10%',
          headerAttributes: headerAttributes,
          attributes: centerAttributes
        },
        {
          field: 'logiDepth2DisNm',
          title: '항목명',
          width: '10%',
          headerAttributes: headerAttributes,
          attributes: centerAttributes
        },
        {
          field: 'seqNo',
          title: '항목순서',
          width: '10%',
          headerAttributes: headerAttributes,
          attributes: centerAttributes
        },
        {
          field: 'useYn',
          title: '사용여부',
          width: '5%',
          headerAttributes: headerAttributes,
          attributes: centerAttributes
        },
      ],
    }
  },
}
</script>

<style scoped>
</style>
